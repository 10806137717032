import React, { useEffect, useRef } from "react";
import "./TopCategories.css";
import p1Image from "./images/p1.png";
import p2Image from "./images/p2.png";
import p4Image from "./images/p4.png";
import p5Image from "./images/p5.png";
import HomePage1 from "./images/HomePage1.png";
import HomePage2 from "./images/HomePage2.png";
import { Link } from "react-router-dom";
import { useState } from "react";
// import ShopByGender from "../Shop-by-Gender/ShopByGender";

const products = [
    { image: p1Image, name: "Rings",link:"Rings" },
    { image: p2Image, name: "Necklace",link:"Necklace" },
    { image: p1Image, name: "Pendants",link:"Pendants" },
    { image: p4Image, name: "Bracelates",link:"Bracelates" },
    { image: p5Image, name: "Earings",link:"Earings" },
];

const TopCategories = () => {
    const containerRef = useRef(null);
    const timeoutId = useRef(null);
    const intervalId = useRef(null);
    const isMouseOver = useRef(false);
    const isScrolling = useRef(false);
    const isUserScrolling = useRef(false);

    useEffect(() => {
        const startAutoScroll = () => {
            intervalId.current = setInterval(() => {
                if (containerRef.current && !isMouseOver.current && !isUserScrolling.current) {
                    const currentScrollLeft = containerRef.current.scrollLeft;
                    const newScrollLeft = currentScrollLeft + 5; // Adjust scroll amount as needed

                    containerRef.current.scrollLeft = newScrollLeft;
                    if (newScrollLeft >= containerRef.current.scrollWidth) {
                        containerRef.current.scrollLeft = 0;
                    }
                }
            }, 10); // Adjust interval for smoother scrolling
        };

        const stopAutoScroll = () => {
            clearInterval(intervalId.current);
        };

        const handleUserScroll = () => {
            if (containerRef.current && !isMouseOver.current) {
                stopAutoScroll();
                containerRef.current.classList.add("scroll-paused");
                isScrolling.current = true;

                clearTimeout(timeoutId.current);
                timeoutId.current = setTimeout(() => {
                    containerRef.current.classList.remove("scroll-paused");
                    isScrolling.current = false;
                    startAutoScroll();
                }, 1000); // 5 seconds
            }
        };

        const handleScroll = () => {
            if (containerRef.current) {
                const maxScrollLeft =
                    containerRef.current.scrollWidth - containerRef.current.clientWidth;
                const scrollLeft = containerRef.current.scrollLeft;

                // Check if scrolled to the end and adjust scroll behavior
                if (scrollLeft >= maxScrollLeft - 1) {
                    containerRef.current.scrollLeft = 0; // Reset to beginning seamlessly
                }
            }
        };

        const handleMouseEnter = () => {
            isMouseOver.current = true;
            stopAutoScroll();
        };

        const handleMouseLeave = () => {
            isMouseOver.current = false;
            startAutoScroll();
        };

        const handleManualScroll = () => {
            isUserScrolling.current = true;
            stopAutoScroll();
            clearTimeout(timeoutId.current);
            // Optional: Add additional logic or delay before resuming auto scroll
        };

        const handleManualScrollEnd = () => {
            isUserScrolling.current = false;
            startAutoScroll();
        };

        if (containerRef.current) {
            containerRef.current.addEventListener("scroll", handleScroll);
            containerRef.current.addEventListener("mouseenter", handleMouseEnter);
            containerRef.current.addEventListener("mouseleave", handleMouseLeave);
            containerRef.current.addEventListener("scroll", handleUserScroll);
            containerRef.current.addEventListener("wheel", handleManualScroll); // Handle mouse wheel scrolling
            containerRef.current.addEventListener("touchstart", handleManualScroll); // Handle touch scrolling
            containerRef.current.addEventListener("mousedown", handleManualScroll); // Handle mouse click and drag scrolling
            containerRef.current.addEventListener("mouseup", handleManualScrollEnd); // Handle end of manual scrolling
        }

        startAutoScroll(); // Start automated scrolling initially

        // Cleanup function
        const cleanup = () => {
            const currentContainerRef = containerRef.current;
            if (currentContainerRef) {
                currentContainerRef.removeEventListener("scroll", handleScroll);
                currentContainerRef.removeEventListener("mouseenter", handleMouseEnter);
                currentContainerRef.removeEventListener("mouseleave", handleMouseLeave);
                currentContainerRef.removeEventListener("scroll", handleUserScroll);
                currentContainerRef.removeEventListener("wheel", handleManualScroll);
                currentContainerRef.removeEventListener("touchstart", handleManualScroll);
                currentContainerRef.removeEventListener("mousedown", handleManualScroll);
                currentContainerRef.removeEventListener("mouseup", handleManualScrollEnd);
            }
            clearInterval(intervalId.current);
            clearTimeout(timeoutId.current);
        };

        // Cleanup on unmount
        return cleanup;
    }, []);

    // Desktop

    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 767);

    useEffect(() => {
      const handleResize = () => setIsDesktop(window.innerWidth > 767);
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Mobile
        const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
      
        useEffect(() => {
          const handleResize = () => setIsMobile(window.innerWidth <= 768);
          window.addEventListener('resize', handleResize);
          return () => window.removeEventListener('resize', handleResize);
        }, []);

    return (

        <>
            <div className="product-slider container products-container">
                <div>
                    <div className="mt-5 future">
                        <div className="header-container">
                            <h1 className="future-head">Top Categories</h1>
                            <button className="button tsp-button">View More</button>
                        </div>
                        <div className="underline"></div>
                    </div>
                </div>

                <div className="horizontal-scroll-container" ref={containerRef}>
                    <div className="product-slide product-card-wrapper">
                        {/* Double the products array to achieve seamless infinite scrolling */}
                        {products.concat(products).map((product, index) => (
                            <div key={index} className="product">
                              <Link to={product.link}><img
                                    src={product.image}
                                    alt={`Product ${index + 1}`}
                                    className="product-image"
                                /></Link> 
                                <h5 className="product-name">{product.name}</h5>
                            </div>
                        ))}
                        {/* Duplicate products enough to cover the width */}
                        {products.concat(products).map((product, index) => (
                            <div key={index + products.length} className="product">
                                <img
                                    src={product.image}
                                    alt={`Product ${index + 1}`}
                                    className="product-image"
                                />
                                <h5 className="product-name" style={{fontFamily:'Baskerville'}}>{product.name}</h5>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Parallax image */}
            <div className="parallax-container">
                <div className="parallax-image">
                    <div className="cms-banner-title-three container">
                        <h3 style={{ color: "#DEAC67"}}>Flat 40% Discount</h3>
                        <h1 style={{fontFamily:'Baskerville'}}>Gold And Diamond Earrings</h1>
                        <p style={{ fontSize: "15px" }}>
                            Designer Diamond Jewellery Necklaces , <br></br> Bracelets , Earrings 
                        </p>
                        <button className="button tsp-button-par " style={{backgroundColor:"#DEAC67"}}><h6>View More</h6></button>
                    </div>
                </div>
            </div>

            {/* Using shop by gender component */}
            <div className="divider-section">
            <h2 className="divider-heading" style={{fontFamily:'Baskerville'}}>RINGS BESTELLERS</h2>
            <p className='text-below-head'>Elegant Rings in Gold , Diamond , Silver and Stones</p>
            <div className="divider-container">
                <img
                    src="divider1.svg"
                    alt="Divider"
                    className="responsive-divider"
                />
            </div>
        </div>


            {/* After Top Category ONLY FOR DESKTOP */}
            {isDesktop && (
        <div className="container after-TopCategories" style={{ width: "100%"}}>
          <div className="image-container">
            <img src={HomePage1} alt="Product 1" className="left-image" />
            <div className="text-overlay left-text">
              <h3 className="shopNow-Head">Gold Newlyweds Rings</h3>
              <button className="shopNow2">Shop Now</button>
            </div>
          </div>
          <div className="image-container">
            <img src={HomePage2} alt="Product 2" className="right-image" />
            <div className="text-overlay right-text">
              <h3 className="shopNow-Head">Silver Earrings</h3>
              <button className="shopNow3">Shop Now</button>
            </div>
          </div>
        </div>
      )}

     {/* ONLY FOR MOBILE */}
     {isMobile && (
     <div className="row mx-n2">
      <div className="region col-6 col-lg-4 px-0">
        <div className="experience-component experience-tile">
          <div className="male-female">
            <div className="gender-div male-div" data-gtm="Men" style={{border:"0.3px solid grey"}}>
              <picture>
                <Link to="Rings" id="men-cat-link">
                  <source media="(max-width: 575px)" src="silverEarings.jpg" />
                  <source media="(min-width: 576px)" src="silverEarings.jpg" />
                  <img 
                    className="gender-image" 
                    src="silverEarings.jpg"  
                    alt="Men" 
                    loading="lazy" 
                    style={{ height: '200px', objectFit: 'cover' }} // Ensure both images have the same height
                  />
                </Link>
              </picture>
              <Link to="Rings">
              <div className="shop-now-button" style={{backgroundColor:"red"}}>
                 <button style={{width:"100%",backgroundColor:"#DEAC67",color:"#FBF8F4",border:"0px"}}>Shop Now</button>
                </div>
                <div className="">
                  <h4 className="head-rings" style={{color:"black",fontFamily:'Baskerville'}} >Silver Rings</h4>
                </div>
               
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="region col-6 col-lg-4 px-0">
        <div className="experience-component experience-tile">
          <div className="male-female">
            <div className="gender-div male-div" data-gtm="Kids" style={{border:"0.1px solid grey"}}>
              <picture>
                <Link to="Rings" id="men-cat-link">
                  <source media="(max-width: 575px)" src="DiamondEarings.jpg" />
                  <source media="(min-width: 576px)" src="DiamondEarings.jpg" />
                  <img 
                    className="gender-image" 
                    src="DiamondEarings.jpg" 
                    alt="Kids" 
                    loading="lazy" 
                    style={{ height: '200px', objectFit: 'cover' }} // Ensure both images have the same height
                  />
                </Link>
              </picture>
              <Link to="Rings">
              <div className="shop-now-button" style={{backgroundColor:"red"}}>
                 <button style={{width:"100%",backgroundColor:"#DEAC67",color:"#FBF8F4",border:"0px"}}>Shop Now</button>
                </div>
                <div className="">
                  <h4 className="head-rings" style={{color:"black",fontFamily:'Baskerville'}} >Gold Rings</h4>
                </div>
               
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="region col-6 col-lg-4 px-0">
        <div className="experience-component experience-tile">
          <div className="male-female">
            <div className="gender-div male-div" data-gtm="Kids" style={{border:"0.1px solid grey"}}>
              <picture>
                <Link to="Rings" id="men-cat-link">
                  <source media="(max-width: 575px)" src="stoneEarings.jpg"  />
                  <source media="(min-width: 576px)" src="stoneEarings.jpg"  />
                  <img 
                    className="male-female" 
                    src="stoneEarings.jpg" 
                    alt="Kids" 
                    loading="lazy" 
                    style={{ height: '200px', objectFit: 'cover' }} // Ensure both images have the same height
                  />
                </Link>
              </picture>
              <Link to="Rings">
              <div className="shop-now-button" style={{backgroundColor:"red"}}>
                 <button style={{width:"100%",backgroundColor:"#DEAC67",color:"#FBF8F4",border:"0px"}}>Shop Now</button>
                </div>
              <div className="">
                  <h4 className="" style={{color:"black",fontFamily:'Baskerville'}} >Stone Rings</h4>
                </div>
               
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="region col-6 col-lg-4 px-0">
        <div className="experience-component experience-tile">
          <div className="male-female">
            <div className="gender-div male-div" data-gtm="Kids" style={{border:"0.1px solid grey"}}>
              <picture>
                <Link to="Rings" id="men-cat-link">
                  <source media="(max-width: 575px)" src="goldEarings.jpg"   />
                  <source media="(min-width: 576px)" src="goldEarings.jpg"   />
                  <img 
                    className="gender-image" 
                    src="goldEarings.jpg" 
                    alt="Kids" 
                    loading="lazy" 
                    style={{ height: '200px', objectFit: 'cover' }} // Ensure both images have the same height
                  />
                </Link>
              </picture>
              <Link to="Rings">
              <div className="shop-now-button" style={{backgroundColor:"red"}}>
                 <button style={{width:"100%",backgroundColor:"#DEAC67",color:"#FBF8F4",border:"0px"}}>Shop Now</button>
                </div>
              <div className="">
                  <h4 className="" style={{color:"black",fontFamily:'Baskerville'}} >Diamond Rings</h4>
                </div>
               
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )}
        </>
    );
};

export default TopCategories;
