import React from 'react';
import './ContactUsPage.css'; // Assuming you have a CSS file for styles

const ContactUsPage = () => {
  return (
    <div className="container .container-Location" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', padding: '20px' }}>

      {/* Right side with information */}
      <div className="info-container" style={{ flex: '2', paddingLeft: '20px' }}>
        <h2>The Mayukh</h2>
        <p>
          Established in 1991, The Mayukh Company is the leading supplier of lubricant oils and greases for industrial use. 
          We provide products for various industries such as automotive, marine (e.g., shipbuilding), construction, mining, and others.
        </p>
        <h3>Address</h3>
        <p>WZ-14B, Manohar Park, East Punjabi Bagh, Near Ashoka Park Metro Station, New Delhi 110026, INDIA</p>
        {/* <h3>Business Hours</h3>
        <p>Monday-Saturday: 10am - 7pm</p>
        <p>Sunday: Closed</p> */}
      </div>
       {/* Left side with map */}
       <div className="map-containerc" style={{ flex: '1', maxWidth: '30%' }}>
        <img
          style={{ width: '100%', height: '300px', borderRadius: '8px' }}
          src='./ChandaniChowk.jpg' // Ensure this path is correct
          alt="map"
        />
      </div>
    </div>
  );
};

export default ContactUsPage;
