import React, { useState, useEffect } from 'react';
import './App.css';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home/Home';
import Earings from './Products/Earings';
import LoadingScreen from './LoadingScreen/LoadingScreen';
import Bracelates from './Products/Bracelates';
import Necklace from './Products/Necklace';
import Rings from './Products/Rings';
import Pendants from './Products/Pendants';
import SignInPage from './User-Authentication/SignInPage';
import ClientAdmin from './Client-Admin/ClientAdmin';
import PrivateRoute from './PrivateRoute';
import ContactUsPage from './ContactUsPage';

function App() {
  const [loading, setLoading] = useState(true);
  const [split, setSplit] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSplit(true);
      const splitTimer = setTimeout(() => {
        setLoading(false);
      }, 1000); // Time for the split animation to complete
      return () => clearTimeout(splitTimer);
    }, 3000); // Time for the loading screen to show
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      {loading && (
        <>
          <LoadingScreen />
          <div className={`split-screen left ${split ? 'slide-left' : ''}`}></div>
          <div className={`split-screen right ${split ? 'slide-right' : ''}`}></div>
        </>
      )}
      {!loading && (
        <Router>
          <>
            <FloatingWhatsApp phoneNumber="8076255880" accountName="Sonal" />
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/ContactUs" element={<ContactUsPage />} />
              <Route path="/Bracelates" element={<Bracelates />} />
              <Route path="/Necklace" element={<Necklace />} />
              <Route path="/Rings" element={<Rings />} />
              <Route path="/Earings" element={<Earings />} />
              <Route path="/Pendants" element={<Pendants />} />
              <Route path="/signin" element={<SignInPage />} />
              <Route
            path="/clientadmin"
            element={
              <PrivateRoute>
                <ClientAdmin />
              </PrivateRoute>
            }
          />
            </Routes>
            <Footer />
          </>
        </Router>
      )}
    </div>
  );
}

export default App;


