import React from 'react';
import { Navigate } from 'react-router-dom';
import { useFirebase } from './firebase';  // Import your Firebase hook to check login status

const PrivateRoute = ({ children }) => {
  const { isLoggedIn } = useFirebase();  // Get the logged-in status from Firebase

  if (!isLoggedIn) {
    // If user is not logged in, redirect to the sign-in page
    return <Navigate to="/signin" />;
  }

  // If logged in, render the child components (the protected route)
  return children;
};

export default PrivateRoute;
