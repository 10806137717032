import TopCategories from '../Top-Categories/TopCategories';
import TopSelectedProducts from '../Top-Selected-Products/TopSelectedProducts';
import CarouselExampleSlidesOnly from '../Coursel-1/CarouselExampleSlidesOnly';
import ShopByGender from '../Shop-by-Gender/ShopByGender';
// import SignInPage from '../User-Authentication/SignInPage';
// import Test from '../User-Authentication/test';

function Home() {
  
  return (
      <>
        {/* <SignInPage /> */}
        <CarouselExampleSlidesOnly />
        <TopCategories />    
        <TopSelectedProducts />  
        <ShopByGender />  
      </>
  );
}

export default Home;