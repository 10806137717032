import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useFirebase } from "../firebase";

const SignInPage = () => {
  const firebase = useFirebase();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();  // Use the navigate hook

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Logging in a user....");

    const result = await firebase.signinUserWithEmailAndPassword(email, password);
    if (result) {
      console.log("Successfully signed in");
      navigate("/clientadmin");  // Redirect to ClientAdmin after successful login
    } else {
      console.log("Failed to sign in");
    }
  };

  return (
    <>

      <h1 className="text-center mb-4">Admin Panel</h1>

      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}  // Controlled input
            required
          />
        </Form.Group>

        <Form.Group controlId="formPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}  // Controlled input
            required
          />
        </Form.Group>

        <Button variant="primary" type="submit" style={{marginTop:"10px"}}>
          Sign In
        </Button>
      </Form>
    </>
  );
};

export default SignInPage;
