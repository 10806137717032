import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faEnvelope, faPhone, faPrint } from '@fortawesome/free-solid-svg-icons';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className="my-5" style={{ marginBottom: '0px'}}>
  <footer className="text-center text-lg-start text-white" style={{ backgroundColor: '#1D8996' }}>
    <div className="container p-4 pb-0">
      <section>
        <div className="row">
        <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
  <div className="d-flex align-items-center">
    <div className="mr-3">
    <img className='logo-footer' src="./Logo-main.jpg" alt="logo" />
    </div>
    <div>
      <p className='logo-text' style={{color:'#FBF8F4'}}>
        Wore a stunning necklace adorned with shimmering pearls and dazzling diamonds, gracefully accentuating her neckline and adding an air of elegance to her attire.
        gracefully accentuating her accentuating accentuating her .
      </p>
    </div>
  </div>
</div>
          <hr className="w-100 clearfix d-md-none" style={{marginTop:'20px'}}/>
          <div className="inner-row">
            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
              <h6 className="text-uppercase mb-4 font-weight-bold" style={{fontFamily:'Baskerville'}}>Products</h6>
              <p>
                <a className="text-white text-18" href="www.google.com">Rings</a>
              </p>
              <p>
                <a className="text-white text-18" href="www.google.com">Necklace</a>
              </p>
              <p>
                <a className="text-white text-18" href="www.google.com">Pendants</a>
              </p>
              <p>
                <a className="text-white text-18" href="www.google.com">Bracelates</a>
              </p>
            </div>
            {/* <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
              <h6 className="text-uppercase mb-4 font-weight-bold">Useful links</h6>
              <p>
                <a className="text-white" href="www.google.com">Your Account</a>
              </p>
              <p>
                <a className="text-white" href="www.google.com">Become an Affiliate</a>
              </p>
              <p>
                <a className="text-white" href="www.google.com">Shipping Rates</a>
              </p>
              <p>
                <a className="text-white" href="www.google.com">Help</a>
              </p>
            </div> */}
            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
              <h6 className="text-uppercase mb-4 font-weight-bold" style={{fontFamily:'Baskerville'}}>Contact</h6>
              <p className='text-18' ><FontAwesomeIcon icon={faHome} className="mr-3" /> Delhi , India</p>
              <p className='text-18'><FontAwesomeIcon icon={faEnvelope} className="mr-3" /> info@gmail.com</p>
              <p className='text-18'><FontAwesomeIcon icon={faPhone} className="mr-3" /> + 01 234 567 88</p>
              <Link to="/signin" style={{ textDecoration: "none" }}>
      <p style={{ cursor: "pointer" }}>
        <FontAwesomeIcon icon={faPrint} className="mr-3" />
        Client Admin
      </p>
    </Link>
            </div>
          </div>
        </div>
      </section>
      <hr className="my-3" />
      <section className="p-3 pt-0">
        <div className="row d-flex align-items-center">
          <div className="col-md-7 col-lg-8 text-center text-md-start">
            <div className="p-3">
              © 2024 Mayukh. All rights reserved.
            </div>
          </div>
          <div className="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">
            <a className="btn btn-outline-light btn-floating m-1 text-white" role="button" href="www.google.com"><i className="fab fa-facebook-f"></i></a>
            <a className="btn btn-outline-light btn-floating m-1 text-white" role="button" href="www.google.com"><i className="fab fa-twitter"></i></a>
            <a className="btn btn-outline-light btn-floating m-1 text-white" role="button" href="www.google.com"><i className="fab fa-google"></i></a>
            <a className="btn btn-outline-light btn-floating m-1 text-white" role="button" href="www.google.com"><i className="fab fa-instagram"></i></a>
          </div>
        </div>
      </section>
    </div>
  </footer>
</div>

  );
}

export default Footer;