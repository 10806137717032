import React from 'react';
import { Link } from 'react-router-dom';
import './ShopByGender.css';

const ShopByGender = () => {
    return (
        <>
             <div className="divider-section">
            <h2 className="divider-heading" style={{fontFamily:'Baskerville'}}>SHOP BY GENDER</h2>
            <p className='text-below-head' style={{fontFamily:'Baskerville'}}>Elegant Collection for Him, Her, and the Kids</p>
            <div className="divider-container">
                <img
                    src="divider1.svg"
                    alt="Divider"
                    className="responsive-divider"
                />
            </div>
        </div>
            <div className="row mx-n2" style={{padding:"20px"}}>
                <div className="region col-6 col-lg-4 px-0">
                    <div className="experience-component experience-tile">
                        <div className="male-female">
                            <div className="gender-div male-div" data-gtm="Men">
                                <picture>
                                    <Link to="Rings" id="men-cat-link">
                                        <source media="(max-width: 575px)" srcSet="https://www.tanishq.co.in/dw/image/v2/BKCK_PRD/on/demandware.static/-/Library-Sites-TanishqSharedLibrary/default/dwef4310c0/homepage/ShopByGender/Men.jpg" />
                                        <source media="(min-width: 576px)" srcSet="https://www.tanishq.co.in/dw/image/v2/BKCK_PRD/on/demandware.static/-/Library-Sites-TanishqSharedLibrary/default/dwef4310c0/homepage/ShopByGender/Men.jpg" />
                                        <img className="gender-image" src="https://www.tanishq.co.in/dw/image/v2/BKCK_PRD/on/demandware.static/-/Library-Sites-TanishqSharedLibrary/default/dwef4310c0/homepage/ShopByGender/Men.jpg" alt="Men" loading="lazy" />
                                    </Link>
                                </picture>
                                <Link to="Rings">
                                    <div className="info-div">
                                        <h6 className="gender-text">Men</h6>
                                        <div className="explore-more">
                                            <span className='explore-More'>Explore More &gt;</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="region col-6 col-lg-4 px-0">
                    <div className="experience-component experience-tile">
                        <div className="male-female">
                            <div className="gender-div male-div" data-gtm="Kids">
                                <picture>
                                    <Link to="Rings" id="men-cat-link">
                                        <source media="(max-width: 575px)" srcSet="https://www.tanishq.co.in/dw/image/v2/BKCK_PRD/on/demandware.static/-/Library-Sites-TanishqSharedLibrary/default/dw24db1907/homepage/ShopByGender/kid.jpg" />
                                        <source media="(min-width: 576px)" srcSet="https://www.tanishq.co.in/dw/image/v2/BKCK_PRD/on/demandware.static/-/Library-Sites-TanishqSharedLibrary/default/dw24db1907/homepage/ShopByGender/kid.jpg" />
                                        <img className="gender-image" src="https://www.tanishq.co.in/dw/image/v2/BKCK_PRD/on/demandware.static/-/Library-Sites-TanishqSharedLibrary/default/dw24db1907/homepage/ShopByGender/kid.jpg" alt="Kids" loading="lazy" />
                                    </Link>
                                </picture>
                                <Link to="Rings">
                                    <div className="info-div">
                                        <h6 className="gender-text">Kids</h6>
                                        <div className="explore-more">
                                        <span className='explore-More'>Explore More &gt;</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="region lastone col-12 col-lg-4 px-0">
                    <div className="experience-component experience-tile">
                        <div className="male-female">
                            <div className="gender-div male-div" data-gtm="Women">
                                <picture>
                                    <Link to="Rings" id="men-cat-link">
                                        <source media="(max-width: 575px)" srcSet="https://www.tanishq.co.in/dw/image/v2/BKCK_PRD/on/demandware.static/-/Library-Sites-TanishqSharedLibrary/default/dwc0abe627/homepage/ShopByGender/Woman.jpg" />
                                        <source media="(min-width: 576px)" srcSet="https://www.tanishq.co.in/dw/image/v2/BKCK_PRD/on/demandware.static/-/Library-Sites-TanishqSharedLibrary/default/dwc0abe627/homepage/ShopByGender/Woman.jpg" />
                                        <img className="gender-image" src="https://www.tanishq.co.in/dw/image/v2/BKCK_PRD/on/demandware.static/-/Library-Sites-TanishqSharedLibrary/default/dwc0abe627/homepage/ShopByGender/Woman.jpg" alt="Women" loading="lazy" />
                                    </Link>
                                </picture>
                                <Link to="Rings">
                                    <div className="info-div">
                                        <h6 className="gender-text">Women</h6>
                                        <div className="explore-more">
                                        <span className='explore-More'>Explore More &gt;</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShopByGender;
