// import { initializeApp } from "firebase/app";
// import { createContext, useContext, useState, useEffect } from "react";
// import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
// import { getFirestore } from "firebase/firestore"; // Import Firestore

// const FirebaseContext = createContext(null);

// const firebaseConfig = {
//   apiKey: "AIzaSyCVOmJeVxPxKybKgUdvWcYdNRMl7L3XBNU",
//   authDomain: "mayukh-1499.firebaseapp.com",
//   projectId: "mayukh-1499",
//   storageBucket: "mayukh-1499.appspot.com",
//   messagingSenderId: "594742973302",
//   appId: "1:594742973302:web:20b0e8b9afb596d637e057",
//   measurementId: "G-JL3XNSCX7G",
//   databaseURL: "https://mayukh-1499-default-rtdb.firebaseio.com"
// };

// export const useFirebase = () => useContext(FirebaseContext);

// const firebaseApp = initializeApp(firebaseConfig);
// const firebaseAuth = getAuth(firebaseApp);
// const db = getFirestore(firebaseApp); // Initialize Firestore

// export const FirebaseProvider = (props) => {
//   const [user, setUser] = useState(null);

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
//       if (user) {
//         setUser(user);
//       } else {
//         setUser(null);
//       }
//     });
//     return () => unsubscribe();
//   }, []);

//   const signinUserWithEmailAndPassword = async (email, password) => {
//     try {
//       const userCredential = await signInWithEmailAndPassword(firebaseAuth, email, password);
//       return userCredential; // Return the userCredential object
//     } catch (error) {
//       console.error("Error signing in:", error);
//     }
//   };

//   const isLoggedIn = user ? true : false;

//   return (
//     <FirebaseContext.Provider value={{ signinUserWithEmailAndPassword, isLoggedIn, db }}>
//       {props.children}
//     </FirebaseContext.Provider>
//   );
// };

import { initializeApp } from "firebase/app";
import { createContext, useContext, useState, useEffect } from "react";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore"; // Firestore
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Storage

const FirebaseContext = createContext(null);

const firebaseConfig = {
  apiKey: "AIzaSyCVOmJeVxPxKybKgUdvWcYdNRMl7L3XBNU",
  authDomain: "mayukh-1499.firebaseapp.com",
  projectId: "mayukh-1499",
  storageBucket: "mayukh-1499.appspot.com",
  messagingSenderId: "594742973302",
  appId: "1:594742973302:web:20b0e8b9afb596d637e057",
  measurementId: "G-JL3XNSCX7G",
  databaseURL: "https://mayukh-1499-default-rtdb.firebaseio.com"
};

export const useFirebase = () => useContext(FirebaseContext);

const firebaseApp = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp); // Initialize Firestore
const storage = getStorage(firebaseApp); // Initialize Firebase Storage

export const FirebaseProvider = (props) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const signinUserWithEmailAndPassword = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(firebaseAuth, email, password);
      return userCredential; // Return the userCredential object
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

  const isLoggedIn = user ? true : false;

  // Function to upload image to Firebase Storage and save the URL to Firestore
  const uploadImageToStorage = async (file, productId) => {
    if (!file) return null;

    const storageRef = ref(storage, `productImages/${file.name}`);
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);

    // Now you can return the URL or save it to Firestore
    return downloadURL;
  };

  return (
    <FirebaseContext.Provider value={{ signinUserWithEmailAndPassword, isLoggedIn, db, uploadImageToStorage ,firebaseAuth }}>
      {props.children}
    </FirebaseContext.Provider>
  );
};

