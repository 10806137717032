import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import './carouselExampleSlidesOnly.css';

function CarouselExampleSlidesOnly() {
  const randomImages = [
    'https://tormeti-fashi.myshopify.com/cdn/shop/files/main-banner-4.png?v=1682066800&width=2136',
    'https://tormeti-fashi.myshopify.com/cdn/shop/files/main-banner-3.png?v=1681714711&width=1800',
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Calculate the next active index
      setActiveIndex((prevIndex) => (prevIndex + 1) % randomImages.length);
    }, 3000); // Change image every 5 seconds
  
    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [randomImages.length]);

  //Toggler automatic close 

  return (
    <>
<body>

<div className="carousel-container">
  <div className="carousel-content">
    <div style={{ display: activeIndex === 0 ? 'block' : 'none' }}>
      <h2 style={{ textAlign: 'left', fontFamily: 'Baskerville', color: '#054B61' }} className=".heading-large">ELEGANT</h2>
      <h2 style={{ textAlign: 'left', fontFamily: 'Baskerville', color: '#054B61' }} className="heading-small">COLLECTIONS</h2>
      <p style={{fontSize:'10px',color: '#054B61'}}>THOUGHTFULLY DESIGNED DIAMONDS</p>
      <button style={{ borderRadius: '30px', backgroundColor: '#054B61',marginRight:'40%' }} className='btn-large'>Shop Now</button>
    </div>
    <div style={{ display: activeIndex === 1 ? 'block' : 'none' }}>
    <h2 style={{ textAlign: 'left', fontFamily: 'Baskerville', color: 'white' }}>ELEGANT</h2>
      <h2 style={{ textAlign: 'left', fontFamily: 'Baskerville', color: 'white' }}>COLLECTIONS</h2>
      <p style={{fontSize:'10px'}}>THOUGHTFULLY DESIGNED DIAMONDS</p>
      <button style={{ borderRadius: '30px', backgroundColor: '#054B61',marginRight:'40%' }} className='btn-large'>Shop Now</button>
    </div>
  </div>
  <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
    <div className="carousel-inner">
      {randomImages.map((imageUrl, index) => (
        <div className={`carousel-item ${index === activeIndex ? 'active' : ''}`} key={index}>
          <img src={imageUrl} className="d-block w-100" alt={`Slide ${index + 1}`} style={{ height: "100%" }} />
        </div>
      ))}
    </div>
  </div>
</div>
<div className="moving-text-container" style={{backgroundColor:'#1D8996'}}>
      <div className="moving-text" style={{marginTop:'8px'}}>
        <h5 style={{color:'#FCFCCA'}}>100% on Rings - Limited offer 60% on rings - Limited offer 40% on rings - Limited offer.  
            10% on rings - Limited offer 30% on Pendants - Limited offer 60% on rings - Limited offer.
            30% on rings - Limited offer 20% on Necklace - Limited offer 30% on rings - Limited offer.
            20% on rings - Limited offer 25% on Rings - Limited offer 20% on rings - Limited offer.
            150% on rings - Limited offer 50% on Bracelates - Limited offer 25% on rings - Limited offer.
            </h5>
      </div>
    </div>

   </body>
      
    </>
  );
}

export default CarouselExampleSlidesOnly;