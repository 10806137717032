import React, { useState, useEffect } from "react";
import ProductCard from "../Product-Card/ProductCard";
import "./products.css";
import ProductDetail from "../Product-Detail/ProductDetail";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useFirebase } from "../firebase"; // Adjust import

const Pendants = () => {
  const { db } = useFirebase(); // Get Firestore instance
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const q = query(collection(db, "products"), where("category", "==", "Pendants")); // Adjust the category name if necessary
      const querySnapshot = await getDocs(q);
      const productsArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(productsArray);
    };

    fetchProducts();
  }, [db]);

  const handleProductClick = (product) => {
    console.log("Selected Product:", product);
    setSelectedProduct(product);
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
  };

  return (
    <>
      <div className='container-fluid top-container' style={{ height: '150px', width: '100%', position: 'relative', backgroundImage: 'url(https://jewelkin-codezeel.myshopify.com/cdn/shop/files/breadcumb_6.jpg?v=1668746632)', backgroundSize: 'cover', backgroundPosition: 'center', padding: '0px' }}>
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', textAlign: 'center' }}>
          <h1 className="future-head" style={{ color: "#031026" }}>Pendants</h1>
          <div className="underline" style={{ color: "#031026" }}></div>
        </div>
      </div>

      <main className="page catalog-page" style={{ marginTop: "20px" }}>
        <section className="clean-block clean-catalog dark">
          <div className="container">
            <div className="content">
              <div className="row" style={{ padding: '0px' }}>
                <div className="col-md-12" style={{ padding: "10px" }}>
                  <div className="products">
                    <div className="row">
                      {products.map((product) => (
                        <div key={product.id} className="col-6 col-md-4 col-lg-3" style={{ padding: "10px" }}>
                          <ProductCard
                            imageUrl={product.mainImageUrl}  // Assuming you have an imageUrl field
                            heading={product.title}        // Product title
                            price={product.price}     // You can format it as needed
                            onClick={() => handleProductClick(product)}
                          />
                        </div>
                      ))}
                    </div>
                    {selectedProduct && (
                      <ProductDetail
                        mainImageUrl={selectedProduct.mainImageUrl}
                        heading={selectedProduct.title}
                        caratRate={selectedProduct.caratRate}
                        type={selectedProduct.type}
                        purity={selectedProduct.purity}
                        goldWeight={selectedProduct.goldWeight}
                        thumbnail1={selectedProduct.thumbnail1} 
                        thumbnail2={selectedProduct.thumbnail2} 
                        onClose={handleCloseModal}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Pendants;
