import React from 'react';
import './ProductCard.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt} from '@fortawesome/free-solid-svg-icons';

const ProductCard = ({ imageUrl, heading, onClick , price}) => {
  return (
    <>
<div >
     <div class="clean-product-item" onClick={onClick}>
          <div class="image"><img class="img-fixed-size d-block mx-auto" src={imageUrl} alt="main"/></div>
              <h2 class="product-name" style={{textAlign:"left"}}>{heading}</h2>
                     <div  style={{textAlign:"left"}}>
                       <p class="price">₹{price}.00</p>
                      </div>
                      <div class="about">
                                       
                     <div className="d-flex flex-row my-3">
                    <h6 className="text-warning mb-1 me-2">
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStarHalfAlt} />
                    </h6>
                    <span className="text-success ms-2 price"style={{marginLeft:"30px"}}>In stock</span>
                </div>
             </div>
        </div>
     </div>
{/* </div> */}
    </>
  );
};

export default ProductCard;