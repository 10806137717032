import React from 'react';
import ProductSlider1 from '../ProductSlider/ProductSlider1';
import ProductSlider2 from '../ProductSlider/ProductSlider2';
import './TopSelectedProducts.css';

function TopSelectedProducts() {
  
  return (
    <div className="container">
          <div>
    <div className="mt-5 future">
    <div className="header-container">
  <h2 className="future-head">Top Selected Products</h2>
  <button className="button tsp-button">View More</button>
</div>
      <div className="underline"></div>
    </div>
  </div>
  <div style={{marginTop:"20px"}}>
      <ProductSlider1 />
      </div>
      <div style={{marginTop:"20px"}}>
      <ProductSlider2 />
      </div>

      <div className="divider-section">
            <h2 className="divider-heading" style={{fontFamily:'Baskerville'}}>MAYUKH EXCLUSIVE</h2>
            <p className='text-below-head' style={{fontFamily:'Baskerville'}}>Elegant Collection for Him, Her, and the Kids</p>
            <div className="divider-container">
                <img
                    src="divider1.svg"
                    alt="Divider"
                    className="responsive-divider"
                />
            </div>
        </div>

      <div className="photo-grid">
            {/* Left Column with 3 images */}
            <div className="left-column">
                <div className="grid-image-container">
                    <img src="/img1.jpg" alt="one" className="grid-image" />
                </div>
                <div className="grid-image-container">
                    <img src="/img2.jpg" alt="two" className="grid-image" />
                </div>
                <div className="grid-image-container">
                    <img src="/img3.jpg" alt="three" className="grid-image" />
                </div>
            </div>

            {/* Right Column with 2 images */}
            <div className="right-column">
                <div className="grid-image-container">
                    <img src="/img4.jpg" alt="four" className="grid-image" />
                </div>
                <div className="grid-image-container">
                    <img src="/img5.jpg" alt="five" className="grid-image" />
                </div>
            </div>
        </div>

        <div style={{marginTop:"20px"}}>
          <ProductSlider1 />
        </div>

    </div>
  );
}

export default TopSelectedProducts;
