import React, { useEffect, useRef } from 'react';
import './ProductSlider.css';

function ProductCard({ imageUrl, heading, price }) {
  return (
    <div className="col-6 col-md-4 col-lg-2 mb-4">
      <div className="card h-100 transparent-card">
        <img className="card-img-top" src={imageUrl} alt={heading} style={{ borderRadius: "5px"}} />
        <div className="card-body">
          <h5 className="card-title" style={{ textAlign: "left" }}>{heading}</h5>
          <h6 style={{ color: "#D9A04C", textAlign: "left" }}>₹ {price}.00</h6>
        </div>
      </div>
    </div>
  );
}

const ProductSlider1 = () => {
  const products = [
    { imageUrl: 'test1.jpg', heading: 'Ring', price: 700 },
    { imageUrl: 'test2.jpg', heading: 'Ring', price: 700 },
    { imageUrl: 'test1.jpg', heading: 'Ring', price: 700 },
    { imageUrl: 'test2.jpg', heading: 'Ring', price: 700 },
    { imageUrl: 'test1.jpg', heading: 'Ring', price: 700 },
    { imageUrl: 'test1.jpg', heading: 'Ring', price: 700 },
    { imageUrl: 'test1.jpg', heading: 'Ring', price: 700 },
    { imageUrl: 'test1.jpg', heading: 'Ring', price: 700 },
    { imageUrl: 'test1.jpg', heading: 'Ring', price: 700 },
    { imageUrl: 'test1.jpg', heading: 'Ring', price: 700 },
    { imageUrl: 'test1.jpg', heading: 'Ring', price: 700 },
    // Add more products here
  ];

  const containerRef = useRef(null);
  const timeoutId = useRef(null);
  const intervalId = useRef(null);

  useEffect(() => {
    const startAutoScroll = () => {
      intervalId.current = setInterval(() => {
        if (containerRef.current) {
          if (
            containerRef.current.scrollLeft + containerRef.current.offsetWidth >=
            containerRef.current.scrollWidth
          ) {
            containerRef.current.scrollLeft = 0;
          } else {
            containerRef.current.scrollLeft += 10; // Adjust scroll amount as needed
          }
        }
      }, 100); // Adjust interval as needed
    };

    const stopAutoScroll = () => {
      clearInterval(intervalId.current);
    };

    const handleUserScroll = () => {
      if (containerRef.current) {
        stopAutoScroll();
        containerRef.current.classList.add('scroll-paused');
        clearTimeout(timeoutId.current);
        timeoutId.current = setTimeout(() => {
          containerRef.current.classList.remove('scroll-paused');
          startAutoScroll();
        }, 1000); // 30 seconds
      }
    };

    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', handleUserScroll);
    }

    startAutoScroll();

    // Cleanup function
    const cleanup = () => {
      const currentContainerRef = containerRef.current;
      if (currentContainerRef) {
        currentContainerRef.removeEventListener('scroll', handleUserScroll);
      }
      clearInterval(intervalId.current);
      clearTimeout(timeoutId.current);
    };

    // Cleanup on unmount
    return cleanup;
  }, []);

  return (
    <div className="horizontal-scroll-container" ref={containerRef} style={{ marginTop: '10px' }}>
      <div className="product-card-wrapper">
        {products.concat(products).map((product, index) => (
          <div key={index} className="product-card">
            <ProductCard
              imageUrl={product.imageUrl}
              heading={product.heading}
              price={product.price}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductSlider1;

